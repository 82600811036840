<template>
  <b-card title="Add Holiday">
    <b-col cols="12">
      <b-row>
        <b-col cols="6">
          <b-form-group
              label="Name"
              description="Name"
            >
              <b-form-input
                v-model="form.name"
                placeholder="Holiday Name"
              ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Holiday"
              description="Holiday"
            >
              <b-form-datepicker dropright v-model="form.holidayDate"></b-form-datepicker>
          </b-form-group>

          <b-btn @click="saveHoliday" class="float-right" variant="primary">
            Save
          </b-btn>
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex';
import axios from '@/axios';

export default {
  data() {
    return {
      form: {
        name: '',
        holidayDate: ''
      }
    }
  },
  methods: {
    saveHoliday() {
      axios.post('sales-and-purchase/holidays', this.form).then((response) => {
        this.$bvToast.toast(`You have successfully created a Holiday ${this.form.name} ${this.form.holidayDate}`, {
          title: "Success",
          variant: "success",
          solid: true,
        });
      }).catch((e) => {
        this.$bvToast.toast(`You have failed to create a Holiday ${this.form.name} ${this.form.holidayDate}`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
      })
    },
  }
}
</script>
